<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-detalle-tepTurno"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Detalle Turno</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Turnos"
                  data-toggle="tab"
                  href="#Turnos"
                  >Turno</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-odometros"
                  data-toggle="tab"
                  href="#Odometros"
                  >Odómetros
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Trazabilidad"
                  data-toggle="tab"
                  href="#Trazabilidad"
                  @click="getTraza(), showGeocercas()"
                  >Trazabilidad
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Inspeccion"
                  data-toggle="tab"
                  href="#Inspeccion"
                  >Inspección
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Viajes"
                  data-toggle="tab"
                  href="#Viajes"
                  >Viajes
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Turnos">
                <div class="container">
                  <!-- Detalle del Turno -->
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="position-relative p-3 m-2 bg-light"
                        style="font-size: 0.85em"
                      >
                        <div class="ribbon-wrapper ribbon-lg">
                          <div
                            class="ribbon"
                            :class="
                              data_turno.estado == 1
                                ? 'badge-success'
                                : data_turno.estado == 2
                                ? 'badge-primary'
                                : data_turno.estado == 3
                                ? 'badge-warning'
                                : data_turno.estado == 4
                                ? 'badge-danger'
                                : data_turno.estado == 5
                                ? 'bg-gray'
                                : 'badge-light'
                            "
                          >
                            <small>{{ data_turno.nEstado }}</small>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12" v-if="data_turno.estado == 5">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_turno.justificacion_cancelacion }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Justificación Cancelación</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-1">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_turno.id }}
                              </h5>
                              <span class="description-text text-secondary"
                                ># Turno</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_turno.vehiculo
                                    ? data_turno.vehiculo.placa
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Vehículo</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_turno.empresa
                                    ? data_turno.empresa.razon_social
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Empresa</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_turno.fecha_ini }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Fecha Inicio</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_turno.fecha_fin
                                    ? data_turno.fecha_fin
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Fecha Fin</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_turno.tipo_vehiculo
                                    ? data_turno.tipo_vehiculo.nombre
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Tipo vehiculo</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_turno.nArea ? data_turno.nArea : "" }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Area</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_turno.turno
                                    ? data_turno.turno.nombre
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Turno</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_turno.nEstadoOperacion
                                    ? data_turno.nEstadoOperacion
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Operacion</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                <small>
                                  Lat. {{ data_turno.lat_ini_conductor }} | Lng.
                                  {{ data_turno.lon_ini_conductor }}
                                </small>
                              </h5>
                              <span class="description-text text-secondary"
                                >Origen Del Conductor</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                <small>
                                  Lat. {{ data_turno.lat_ini_vehiculo }} | Lng.
                                  {{ data_turno.lon_ini_vehiculo }}
                                </small>
                              </h5>
                              <span class="description-text text-secondary"
                                >Origen Del Vehículo</span
                              >
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                <small>
                                  Lat. {{ data_turno.lat_fin_conductor }} | Lng.
                                  {{ data_turno.lon_fin_conductor }}
                                </small>
                              </h5>
                              <span class="description-text text-secondary"
                                >Destino Del Conductor</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                <small>
                                  Lat. {{ data_turno.lat_fin_vehiculo }} | Lng.
                                  {{ data_turno.lon_fin_vehiculo }}
                                </small>
                              </h5>
                              <span class="description-text text-secondary"
                                >Destino Del Vehículo</span
                              >
                            </div>
                          </div>
                          <!-- Datos resumen del la gestión el turno -->
                          <div
                            class="col-md-12"
                            v-if="
                              data_turno.tep_preliquidacion_internos &&
                                data_turno.tep_preliquidacion_internos.length >
                                  0
                            "
                          >
                            <div class="row">
                              <div class="col-md-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      data_turno.tep_preliquidacion_internos
                                        ? data_turno
                                            .tep_preliquidacion_internos[0]
                                            .km_recorridos
                                        : ""
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Kilómetros Recorridos</span
                                  >
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      data_turno.tep_preliquidacion_internos[0]
                                        .horas_trabajadas
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Horas Trabajadas</span
                                  >
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      data_turno.tep_preliquidacion_internos[0]
                                        .km_add
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Kilómetros Adicionales</span
                                  >
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      data_turno.tep_preliquidacion_internos[0]
                                        .horas_extra
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Horas Extra</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12" v-else>
                            <div class="row">
                              <div
                                class="col-md-3"
                                v-if="
                                  data_turno.odometro_ini &&
                                    data_turno.odometro_fin
                                "
                              >
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      data_turno.odometro_fin -
                                        data_turno.odometro_ini
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Kilómetros Recorridos</span
                                  >
                                </div>
                              </div>
                              <div
                                class="col-md-3"
                                v-if="
                                  data_turno.fecha_ini && data_turno.fecha_fin
                                "
                              >
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      getHoras(
                                        data_turno.fecha_ini,
                                        data_turno.fecha_fin
                                      )
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Horas Trabajadas</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="row justify-content-md-center">
                    <div class="col-md-12">
                      <div class="card card-info">
                        <div class="card-header">
                          <h3 class="card-title">Vehículo y Conductor</h3>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <!-- Vehículo -->
                            <div
                              class="
                                col-12 col-sm-6 col-md-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                              v-if="data_turno.vehiculo != null"
                            >
                              <div class="card bg-white d-flex flex-fill">
                                <div
                                  class="card-header text-muted border-bottom-0"
                                >
                                  Vehículo
                                </div>
                                <div class="card-body pt-0">
                                  <div class="row">
                                    <div
                                      :class="
                                        data_turno.vehiculo &&
                                        data_turno.vehiculo.link_fotografia
                                          ? 'col-md-7'
                                          : 'col-md-12'
                                      "
                                    >
                                      <h2 class="lead">
                                        <b>{{
                                          data_turno.vehiculo
                                            ? data_turno.vehiculo.placa
                                            : ""
                                        }}</b>
                                      </h2>

                                      <ul class="ml-4 mb-0 fa-ul text-muted">
                                        <li class="small">
                                          <span class="fa-li"
                                            ><i class="fas fa-lg fa-car"></i
                                          ></span>
                                          Tipo Vehículo:
                                          {{
                                            data_turno.vehiculo
                                              ? data_turno.vehiculo
                                                  .tipo_vehiculo &&
                                                data_turno.vehiculo
                                                  .tipo_vehiculo.nombre
                                              : ""
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                    <div
                                      class="col-md-5 text-center"
                                      v-if="
                                        data_turno.vehiculo
                                          ? data_turno.vehiculo.link_fotografia
                                          : ''
                                      "
                                    >
                                      <img
                                        :src="
                                          data_turno.vehiculo &&
                                          data_turno.vehiculo.link_fotografia
                                            ? uri_docs +
                                              data_turno.vehiculo
                                                .link_fotografia
                                            : ''
                                        "
                                        alt="Vehículo"
                                        class="img-circle img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-else
                              class="
                                col-12 col-sm-6 col-md-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                            >
                              <div class="small-box bg-info">
                                <div class="inner">
                                  <h3>No hay vehículo</h3>
                                </div>
                                <div class="icon">
                                  <i class="fas fa-car"></i>
                                </div>
                              </div>
                            </div>
                            <!-- CONDUCTOR 1 -->
                            <div
                              class="
                                col-md-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                              v-if="data_turno.conductor"
                            >
                              <div class="card bg-white d-flex flex-fill">
                                <div
                                  class="card-header text-muted border-bottom-0"
                                >
                                  Conductor
                                </div>
                                <div class="card-body pt-0">
                                  <div class="row">
                                    <div
                                      :class="
                                        data_turno.conductor &&
                                        data_turno.conductor.link_fotografia
                                          ? 'col-md-7'
                                          : 'col-md-12'
                                      "
                                    >
                                      <h2 class="lead">
                                        <b
                                          >{{
                                            data_turno.conductor
                                              ? data_turno.conductor.nombres
                                              : ""
                                          }}
                                          {{
                                            data_turno.conductor
                                              ? data_turno.conductor.apellidos
                                              : ""
                                          }}
                                        </b>
                                      </h2>
                                      <p class="text-muted text-md">
                                        <b>Documento: </b>
                                        {{
                                          data_turno.conductor
                                            ? data_turno.conductor
                                                .numero_documento
                                            : ""
                                        }}
                                      </p>
                                      <ul class="ml-4 mb-0 fa-ul text-muted">
                                        <li class="small">
                                          <span class="fa-li"
                                            ><i
                                              class="fas fa-lg fa-envelope"
                                            ></i
                                          ></span>
                                          E-mail:
                                          {{
                                            data_turno.conductor
                                              ? data_turno.conductor.email
                                              : ""
                                          }}
                                        </li>
                                        <li class="small">
                                          <span class="fa-li"
                                            ><i class="fas fa-lg fa-mobile"></i
                                          ></span>
                                          Celular Principal:
                                          {{
                                            data_turno.conductor
                                              ? data_turno.conductor
                                                  .celular_principal
                                              : ""
                                          }}
                                        </li>
                                        <li class="small">
                                          <span class="fa-li"
                                            ><i class="fas fa-lg fa-phone"></i
                                          ></span>
                                          Celular Alterno:
                                          {{
                                            data_turno.conductor
                                              ? data_turno.conductor
                                                  .celular_alterno
                                              : ""
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                    <div
                                      class="col-md-5 text-center"
                                      v-if="
                                        data_turno.conductor
                                          ? data_turno.conductor.link_fotografia
                                          : ''
                                      "
                                    >
                                      <img
                                        :src="
                                          data_turno.conductor &&
                                          data_turno.conductor.link_fotografia
                                            ? uri_docs +
                                              data_turno.conductor
                                                .link_fotografia
                                            : ''
                                        "
                                        alt="Conductor"
                                        class="img-circle img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-else
                              class="
                                col-12 col-sm-6 col-md-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                            >
                              <div class="small-box bg-info">
                                <div class="inner">
                                  <h3>No hay conductor</h3>
                                </div>
                                <div class="icon">
                                  <i class="fas fa-user"></i>
                                </div>
                              </div>
                            </div>
                            <!-- CONDUCTOR 2 -->
                            <div
                              class="
                                col-md-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                              v-if="data_turno.conductor2"
                            >
                              <div class="card bg-white d-flex flex-fill">
                                <div
                                  class="card-header text-muted border-bottom-0"
                                >
                                  Conductor 2
                                </div>
                                <div class="card-body pt-0">
                                  <div class="row">
                                    <div
                                      :class="
                                        data_turno.conductor2 &&
                                        data_turno.conductor2.link_fotografia
                                          ? 'col-md-7'
                                          : 'col-md-12'
                                      "
                                    >
                                      <h2 class="lead">
                                        <b
                                          >{{
                                            data_turno.conductor2
                                              ? data_turno.conductor2.nombres
                                              : ""
                                          }}
                                          {{
                                            data_turno.conductor2
                                              ? data_turno.conductor2.apellidos
                                              : ""
                                          }}
                                        </b>
                                      </h2>
                                      <p class="text-muted text-md">
                                        <b>Documento: </b>
                                        {{
                                          data_turno.conductor2
                                            ? data_turno.conductor2
                                                .numero_documento
                                            : ""
                                        }}
                                      </p>
                                      <ul class="ml-4 mb-0 fa-ul text-muted">
                                        <li class="small">
                                          <span class="fa-li"
                                            ><i
                                              class="fas fa-lg fa-envelope"
                                            ></i
                                          ></span>
                                          E-mail:
                                          {{
                                            data_turno.conductor2
                                              ? data_turno.conductor2.email
                                              : ""
                                          }}
                                        </li>
                                        <li class="small">
                                          <span class="fa-li"
                                            ><i class="fas fa-lg fa-mobile"></i
                                          ></span>
                                          Celular Principal:
                                          {{
                                            data_turno.conductor2
                                              ? data_turno.conductor2
                                                  .celular_principal
                                              : ""
                                          }}
                                        </li>
                                        <li class="small">
                                          <span class="fa-li"
                                            ><i class="fas fa-lg fa-phone"></i
                                          ></span>
                                          Celular Alterno:
                                          {{
                                            data_turno.conductor2
                                              ? data_turno.conductor2
                                                  .celular_alterno
                                              : ""
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                    <div
                                      class="col-md-5 text-center"
                                      v-if="
                                        data_turno.conductor2
                                          ? data_turno.conductor2
                                              .link_fotografia
                                          : ''
                                      "
                                    >
                                      <img
                                        :src="
                                          data_turno.conductor2 &&
                                          data_turno.conductor2.link_fotografia
                                            ? uri_docs +
                                              data_turno.conductor2
                                                .link_fotografia
                                            : ''
                                        "
                                        alt="Conductor"
                                        class="img-circle img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane" id="Odometros">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card-body">
                        <!-- odometro -->
                        <div class="row justify-content-md-center">
                          <div
                            class="col-md-12"
                            v-if="
                              data_turno.odometro_ini != null ||
                                data_turno.odometro_fin != null
                            "
                          >
                            <div class="position-relative p-3 m-2 bg-light">
                              <div class="ribbon-wrapper ribbon-lg">
                                <div class="ribbon bg-primary">Odometro</div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div
                                      class="
                                        col-sm-6
                                        d-flex
                                        align-items-stretch
                                        flex-column
                                      "
                                      v-if="data_turno.odometro_ini"
                                    >
                                      <div
                                        class="card bg-white d-flex flex-fill"
                                      >
                                        <div
                                          class="
                                            card-header
                                            text-muted
                                            border-bottom-0
                                          "
                                        >
                                          Odómetro Inicial
                                        </div>
                                        <div class="card-body pt-0">
                                          <div class="row">
                                            <div class="col-md-6">
                                              <h3 class="lead">
                                                <b>{{
                                                  data_turno.odometro_ini
                                                }}</b>
                                              </h3>
                                            </div>
                                            <div
                                              class="col-md-8 text-center"
                                              v-if="
                                                data_turno.link_odometro_ini
                                              "
                                            >
                                              <img
                                                @click="
                                                  getImage(
                                                    data_turno.link_odometro_ini
                                                  )
                                                "
                                                :src="
                                                  uri_docs +
                                                    data_turno.link_odometro_ini
                                                "
                                                alt="Odómetro Inicial"
                                                class="
                                                  img-bordered
                                                  img-rounded
                                                  img-fluid
                                                "
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="
                                        col-md-6
                                        d-flex
                                        align-items-stretch
                                        flex-column
                                      "
                                      v-if="data_turno.odometro_fin"
                                    >
                                      <div
                                        class="card bg-white d-flex flex-fill"
                                      >
                                        <div
                                          class="
                                            card-header
                                            text-muted
                                            border-bottom-0
                                          "
                                        >
                                          Odómetro Final
                                        </div>
                                        <div class="card-body pt-0">
                                          <div class="row">
                                            <div class="col-md-5">
                                              <h3 class="lead">
                                                <b>{{
                                                  data_turno.odometro_fin
                                                }}</b>
                                              </h3>
                                            </div>
                                            <div
                                              class="col-md-8 text-center"
                                              v-if="
                                                data_turno.link_odometro_fin
                                              "
                                            >
                                              <img
                                                @click="
                                                  getImage(
                                                    data_turno.link_odometro_fin
                                                  )
                                                "
                                                :src="
                                                  uri_docs +
                                                    data_turno.link_odometro_fin
                                                "
                                                alt="Odómetro Final"
                                                class="
                                                  img-bordered
                                                  img-rounded
                                                  img-fluid
                                                "
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            class="
                              col-md-12
                              d-flex
                              align-items-stretch
                              flex-column
                            "
                          >
                            <div
                              class="small-box bg-info"
                              style="min-height: 100px"
                            >
                              <div class="inner">
                                <h3>No hay Odometros</h3>
                              </div>
                              <div class="icon">
                                <i class="far fa-eye-slash"></i>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-6"
                            v-if="
                              data_turno.odometro_ini != null &&
                                data_turno.odometro_fin != null
                            "
                          >
                            <div class="position-relative p-3 m-2 bg-light">
                              <div class="ribbon-wrapper ribbon-lg">
                                <div class="ribbon bg-primary">km</div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div
                                      class="
                        col-sm-12
                        d-flex
                        align-items-stretch
                        flex-column
                        "
                                    >
                                      <div
                                        class="card bg-white d-flex flex-fill"
                                      >
                                        <div
                                          class="
                            card-header
                            text-muted
                            border-bottom-0
														text-center
                            "
                                        >
                                          Kilómetros Recorridos
                                        </div>
                                        <div class="card-body pt-0">
                                          <div class="row">
                                            <div class="col-md-12 text-center">
                                              <h3 class="lead">
                                                <b>{{
                                                  data_turno.odometro_fin -
                                                    data_turno.odometro_ini
                                                }}</b>
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            class="
              col-md-12
              d-flex
              align-items-stretch
              flex-column
              "
                          >
                            <div
                              class="small-box bg-info"
                              style="min-height: 100px"
                            >
                              <div class="inner">
                                <h3>No Hay Kilómetros Recorridos</h3>
                              </div>
                              <div class="icon">
                                <i class="far fa-eye-slash"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                v-if="traza_viaje != null"
                id="Trazabilidad"
              >
                <div class="card-body" v-if="traza_viaje">
                  <div class="row">
                    <div class="col-md-8">
                      <gmap-map
                        :center="center"
                        :zoom="zoom"
                        style="width: 100%; height: 650px"
                      >
                        <gmap-marker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          :icon="m.icon"
                          :animation="2"
                          @click="toggleInfoWindow(m, index, m.info)"
                        ></gmap-marker>
                        <gmap-marker
                          :key="index"
                          v-for="(n, index) in markers_viaje"
                          :position="n.position"
                          :icon="n.icon"
                          :animation="3"
                          @click="toggleInfoWindow(n, index, n.info)"
                        ></gmap-marker>
                        <gmap-info-window
                          :options="infoOptions"
                          :position="infoWindowPos"
                          :opened="infoWinOpen"
                          @closeclick="infoWinOpen = false"
                        >
                          <div v-html="infoContent"></div>
                        </gmap-info-window>
                        <gmap-polygon
                          v-for="poligono in poligonos"
                          :options="{ fillColor: poligono.color }"
                          :key="poligono.id"
                          :paths="poligono.path"
                          :editable="false"
                          :draggable="false"
                          @click="
                            toggleInfoWindowPoli(
                              poligono,
                              poligono.id,
                              poligono.info
                            )
                          "
                        />
                        <gmap-circle
                          v-for="circular in circunferencias"
                          :key="circular.id"
                          :center="circular.center"
                          :radius="circular.radius"
                          :info="circular.info"
                          @click="
                            toggleInfoWindowCir(
                              circular,
                              circular.id,
                              circular.info
                            )
                          "
                        ></gmap-circle>
                      </gmap-map>
                    </div>
                    <div class="col-md-4">
                      <!-- Origen y destino -->
                      <table
                        class="table table-sm table-striped table-bordered"
                      >
                        <thead class="thead-dark">
                          <tr>
                            <th colspan="1" class="text-center"></th>
                            <th colspan="1" class="text-center">Longitud</th>
                            <th colspan="1" class="text-center">Latitud</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">
                              <span class="badge bg-olive"
                                >Coordenadas Iniciales</span
                              >
                            </td>
                            <td>
                              {{ data_turno.lon_ini_vehiculo }}
                            </td>

                            <td>
                              {{ data_turno.lat_ini_vehiculo }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">
                              <span class="badge bg-pink"
                                >Coordenadas Finales</span
                              >
                            </td>
                            <td>
                              {{ data_turno.lon_fin_vehiculo }}
                            </td>
                            <td>
                              {{ data_turno.lat_fin_vehiculo }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div>
                        <div>
                          <div class="btn-group float-right pl-2">
                            <button
                              type="button"
                              class="btn btn-success btn-lg"
                              @click="generarListadoExcel()"
                            >
                              <i class="far fa-file-excel"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="tab-pane" id="Trazabilidad">
                <div class="card-body">
                  <div class="info-box bg-gradient-warning">
                    <span class="info-box-icon"
                      ><i class="fas fa-exclamation-triangle"></i
                    ></span>
                    <div class="info-box-content">
                      <span class="info-box-text"
                        >Usted aun no tiene trazabilidad</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="Inspeccion">
                <div class="container">
                  <!-- Detalle del Turno -->
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="position-relative p-3 m-2 bg-light"
                        style="font-size: 0.85em"
                      >
                        <div
                          class="ribbon-wrapper ribbon-lg"
                          v-if="
                            data_turno.inspeccion !== null &&
                              data_turno.inspeccion !== undefined
                          "
                        >
                          <div
                            class="ribbon"
                            :class="[
                              data_turno.inspeccion.estado == 1
                                ? 'badge-secondary'
                                : data_turno.inspeccion.estado == 2
                                ? 'badge-success'
                                : data_turno.inspeccion.estado == 3
                                ? 'badge-success'
                                : data_turno.inspeccion.estado == 4
                                ? 'badge-danger'
                                : data_turno.inspeccion.estado == 5
                                ? 'bg-olive'
                                : 'badge-success',
                            ]"
                          >
                            <small>{{ data_turno.nEstadoInspeccion }}</small>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5
                                class="description-header"
                                v-if="
                                  data_turno.inspeccion !== null &&
                                    data_turno.inspeccion !== undefined
                                "
                              >
                                {{ data_turno.inspeccion.id }}
                              </h5>
                              <h5 v-else></h5>
                              <span class="description-text text-secondary"
                                ># Inspección</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-1">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_turno.id }}
                              </h5>
                              <span class="description-text text-secondary"
                                ># Turno</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5
                                class="description-header"
                                v-if="
                                  data_turno.inspeccion !== null &&
                                    data_turno.inspeccion !== undefined
                                "
                              >
                                {{
                                  data_turno.inspeccion.solicitud
                                    ? data_turno.inspeccion.solicitud.id
                                    : "Preop"
                                }}
                              </h5>
                              <h5 v-else></h5>
                              <span class="description-text text-secondary"
                                >Solicitud</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5
                                class="description-header"
                                v-if="
                                  data_turno.inspeccion !== null &&
                                    data_turno.inspeccion !== undefined
                                "
                              >
                                {{ data_turno.inspeccion.fecha_inicio }}
                              </h5>
                              <h5 v-else></h5>
                              <span class="description-text text-secondary"
                                >Fecha</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="description-block">
                              <h5
                                class="description-header"
                                v-if="
                                  data_turno.inspeccion !== null &&
                                    data_turno.inspeccion !== undefined
                                "
                              >
                                {{
                                  data_turno.inspeccion.formato_inspeccion
                                    .nombre
                                }}
                              </h5>
                              <h5 v-else></h5>
                              <span class="description-text text-secondary"
                                >Formato</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5
                                class="description-header"
                                v-if="
                                  data_turno.inspeccion !== null &&
                                    data_turno.inspeccion !== undefined
                                "
                              >
                                {{ data_turno.inspeccion.sitio.nombre }}
                              </h5>
                              <h5 v-else></h5>
                              <span class="description-text text-secondary"
                                >Sitio</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5
                                class="description-header"
                                v-if="
                                  data_turno.inspeccion !== null &&
                                    data_turno.inspeccion !== undefined
                                "
                              >
                                {{ data_turno.inspeccion.empresa.razon_social }}
                              </h5>
                              <h5 v-else></h5>
                              <span class="description-text text-secondary"
                                >Empresa</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5
                                class="description-header"
                                v-if="
                                  data_turno.inspeccion !== null &&
                                    data_turno.inspeccion !== undefined
                                "
                              >
                                {{ data_turno.inspeccion.vehiculo.placa }}
                              </h5>
                              <h5 v-else></h5>
                              <span class="description-text text-secondary"
                                >Vehiculo</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="description-block">
                              <h5
                                class="description-header"
                                v-if="
                                  data_turno.inspeccion !== null &&
                                    data_turno.inspeccion !== undefined
                                "
                              >
                                {{ data_turno.inspeccion.conductor.nombres }}
                                {{ data_turno.inspeccion.conductor.apellidos }}
                              </h5>
                              <h5 v-else></h5>
                              <span class="description-text text-secondary"
                                >Conductor</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-1"></div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5
                                class="description-header"
                                v-if="
                                  data_turno.inspeccion !== null &&
                                    data_turno.inspeccion !== undefined &&
                                    data_turno.odometro_ini != null &&
                                    data_turno.odometro_fin != null
                                "
                              >
                                {{
                                  data_turno.odometro_fin -
                                    data_turno.odometro_ini
                                }}
                              </h5>
                              <h5 v-else></h5>
                              <span class="description-text text-secondary">
                                Km Recorridos</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="description-block">
                              <h5
                                class="description-header"
                                v-if="
                                  data_turno.inspeccion !== null &&
                                    data_turno.inspeccion !== undefined
                                "
                              >
                                {{
                                  data_turno.inspeccion.estado_documento == 1
                                    ? "Borrador"
                                    : "Definitivo"
                                }}
                              </h5>
                              <h5 v-else></h5>
                              <span class="description-text text-secondary"
                                >Estado documento</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <div
                                v-if="
                                  data_turno.inspeccion !== null &&
                                    data_turno.inspeccion !== undefined
                                "
                              >
                                <div
                                  v-if="
                                    data_turno.inspeccion.estado_documento == 2
                                  "
                                >
                                  <button
                                    type="button"
                                    class="btn btn-sm bg-danger"
                                    v-if="
                                      $store.getters.can(
                                        'tep.inspecciones.verPdf'
                                      )
                                    "
                                    @click="verPDF(data_turno.inspeccion.id)"
                                  >
                                    <i class="fas fa-file-pdf"></i>
                                  </button>
                                </div>
                                <h5 v-else></h5>
                              </div>
                              <div v-else>
                                <h5></h5>
                              </div>

                              <span class="description-text text-secondary"
                                >Estado documento</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                v-if="data_turno.viajes.length > 0"
                id="Viajes"
              >
                <div class="card-body p-0">
                  <div class="container px-2 pt-2">
                    <div class="row justify-content-md-center">
                      <div class="col-md-12">
                        <div class="card card-primary">
                          <div class="card-header">
                            <h3 class="card-title">Viajes</h3>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12 px-4 pt-2 pb-2">
                                <table
                                  id="users"
                                  class="
                                    table
                                    table-bordered
                                    table-striped
                                    table-hover
                                    table-sm
                                  "
                                >
                                  <thead>
                                    <tr>
                                      <th>N° Viaje</th>
                                      <th>Conductor</th>
                                      <th>Vehiculo</th>
                                      <th>Odometro Inicial</th>
                                      <th>Odometro Final</th>
                                      <th>Estado</th>
                                      <th>Empresa</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in data_turno.viajes"
                                      :key="item.id"
                                    >
                                      <td>
                                        {{ item.id }}
                                      </td>

                                      <td v-if="item.conductor">
                                        {{ item.conductor.nombres }}
                                        {{ item.conductor.apellidos }}
                                      </td>
                                      <td v-else></td>
                                      <td v-if="item.vehiculo">
                                        {{ item.vehiculo.placa }}
                                      </td>
                                      <td v-else></td>
                                      <td>
                                        {{ item.odometro_ini }}
                                      </td>
                                      <td>
                                        {{ item.odometro_fin }}
                                      </td>

                                      <td class="text-center">
                                        <div v-if="item.estado == 1">
                                          <span class="badge badge-info">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 2">
                                          <span class="badge badge-warning">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 3">
                                          <span class="badge badge-success">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 4">
                                          <span class="badge badge-primary">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 5">
                                          <span class="badge badge-info">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 6">
                                          <span class="badge badge-danger">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 7">
                                          <span class="badge badge-danger">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 8">
                                          <span class="badge badge-primary">
                                            {{
                                              item.nEstado +
                                                "/ " +
                                                item.tep_solicitud_padre_id
                                            }}
                                          </span>
                                        </div>
                                      </td>
                                      <td v-if="item.transportadora">
                                        {{ item.transportadora.razon_social }}
                                      </td>
                                      <td v-else></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TepTurnoDetalle",
  components: {},
  data() {
    return {
      data_turno: {
        conductor: {},
        vehiculo: {},
        viajes: {},
      },
      mensaje_error: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      turno_id: null,
      traza_viaje: {},
      center: {},
      zoom: 10,
      markers: [],
      markers_viaje: [],
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      locations: {},
      poligonos: [],
      circunferencias: [],
    };
  },
  methods: {
    async llenar_modal(item) {
      this.data_turno = {};
      this.data_turno = item;
    },
    getImage(url) {
      window.open(this.uri_docs + url, "_blank");
    },
    getTraza() {
      if (this.turno_id != this.data_turno.id) {
        this.$parent.cargando = true;
        this.turno_id = this.data_turno.id;
        const params = {
          vehiculo_id: this.data_turno.vehiculo_id,
          fecha_inicial: this.data_turno.fecha_ini,
          fecha_final: this.data_turno.fecha_fin,
        };
        axios
          .get("/api/tep/turno/trazabilidad", {
            params,
          })
          .then((response) => {
            if (!response.data.msg_error) {
              this.traza_viaje = response.data.traza;
              const datos_mapa = response.data.datos_mapa;
              this.center = {
                lat: parseFloat(datos_mapa.centro_mapa.latitud),
                lng: parseFloat(datos_mapa.centro_mapa.longitud),
              };
              this.zoom = datos_mapa.zoom;
              this.mensaje_error = null;
              this.getMapa();
            } else {
              this.$swal({
                icon: "error",
                title: response.data.msg_error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              this.center = {
                lat: parseFloat(this.data_turno.lat_ini_vehiculo),
                lng: parseFloat(this.data_turno.lon_ini_vehiculo),
              };
              this.mensaje_error = response.data.msg_error;
              this.traza_viaje = null;
              this.zoom = 16;
              this.$parent.cargando = false;
            }
          });
      }
    },
    getHoras(fecha_ini, fecha_fin) {
      const inicio = new Date(fecha_ini).getTime();
      const fin = new Date(fecha_fin).getTime();
      const result = (fin - inicio) / (1000 * 60 * 60);
      return result.toFixed(2);
    },

    async getMapa() {
      const origen = {
        lat: parseFloat(this.data_turno.lat_ini_vehiculo),
        lng: parseFloat(this.data_turno.lon_ini_vehiculo),
      };
      const destino = {
        lat: parseFloat(this.data_turno.lat_fin_vehiculo),
        lng: parseFloat(this.data_turno.lon_fin_vehiculo),
      };
      await this.addMarkers(origen, destino);
    },
    async addMarkers(origen, destino) {
      await this.limpiarMapa();
      await this.traza_viaje.forEach((traza) => {
        const punto = {
          lat: parseFloat(traza.dblLatitud),
          lng: parseFloat(traza.dblLongitud),
        };
        const info = `
          <div class="wrapper">
            <h3 style="font-size: 15px" class="text-center">Vehículo: ${traza.strPlaca}</h3>
            <hr />
            <b>Fecha reporte:</b> ${traza.dteFechaReporte}<br />
            <b>Posicion:</b> ${traza.dblLatitud}, ${traza.dblLongitud} <br />
            <b>Velocidad:</b> ${traza.intVelocidadInstantanea} Km/h<br />
            <b>Transportadora:</b> ${this.data_turno.empresa.razon_social}<br />
            <b>Distancia Recorrida:</b> ${traza.intDistanciaRecorrida}
          </div>
        `;
        this.markers_viaje.push({
          id: traza.id,
          position: punto,
          info,
          icon: "/img/icon_viaje.png",
        });
      });

      // Datos del origen
      const info_origen = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Origen: ${this.data_turno.sitio.nombre}</h3>
          <hr />
          <b>Iniciado:</b> ${this.data_turno.fecha_ini}<br />
        </div>
      `;
      await this.markers.push({
        position: origen,
        info: info_origen,
        icon: "/img/icon_punto_g_ok.png",
      });

      // Datos del destino
      const info_destino = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Origen: ${this.data_turno.sitio.nombre}</h3>
          <hr />
          <b>Iniciado:</b> ${this.data_turno.fecha_fin}<br />
        </div>
      `;

      await this.markers.push({
        position: destino,
        info: info_destino,
        icon: "/img/icon_punto_g.png",
      });

      this.$parent.cargando = false;
    },
    async showGeocercas() {
      this.cargando = true;
      await axios.get("/api/tep/mapas/showGeocercasZona").then((response) => {
        this.cargando = false;
        this.locations = response.data.gps_fin;
        this.locations.geocerca_poli.forEach((geocerca) => {
          if (geocerca.puntos.length > 0) {
            let puntos = [];
            geocerca.puntos.forEach((punto) => {
              puntos.push({
                lng: parseFloat(punto.longitud),
                lat: parseFloat(punto.latitud),
              });
            });

            this.poligonos.push({
              id: geocerca.id,
              name: geocerca.desc,
              path: puntos,
              info: geocerca.ventana,
              // color: geocerca.color,
            });
          }
        });
        for (var j = 0; j < this.locations.geocerca_cir.length; j++) {
          if (this.locations.geocerca_cir[j]["lat"] !== "") {
            this.circunferencias.push({
              center: {
                lat: parseFloat(this.locations.geocerca_cir[j]["lat"]),
                lng: parseFloat(this.locations.geocerca_cir[j]["lon"]),
              },
              radius: parseFloat(this.locations.geocerca_cir[j]["radio"]),
              info: this.locations.geocerca_cir[j]["ventana"],
            });
          }
        }
      });

      this.cargando = false;
    },
    toggleInfoWindow: function(marker, idx, ventana) {
      this.infoWindowPos = marker.position;
      this.infoContent = ventana;
      this.center = marker.position;

      //comprueba si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    toggleInfoWindowPoli: function(poli, idx, ventana) {
      this.infoWindowPos = poli.path[0];
      this.infoContent = ventana;
      this.center = poli.path[0];

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    toggleInfoWindowCir: function(cir, idx, ventana) {
      this.infoWindowPos = cir.center;
      this.infoContent = ventana;
      this.center = cir.center;

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    verPDF(inspeccion_id) {
      this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/tep/inspecciones/pdf/" + inspeccion_id,
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
    limpiarMapa() {
      this.markers = [];
      this.markers_viaje = [];
    },
    generarListadoExcel() {
      let turno_id = this.data_turno.id;
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/turno/mapas/exportTepTraza",
        data: { turno_id },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
};
</script>
